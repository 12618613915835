import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../../components/Common/Layout";
import CompanyLogo from "../../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../../components/Website/ProjectsNavigation";
import ServicePellet from "../../../components/Website/ServicePellet";

const Vortex = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="pt"
      title="Brocode Studio || Vortex"
      description="Cryptocurrency exchange para Vortex."
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                {" "}
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Vortex</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <ServicePellet service="Branding" />
                  <ServicePellet service="Development" />
                  <p class="wow move-up mb--20">
                    Cryptocurrency exchange para Vortex.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[2]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col xs={12}>
                <Img fluid={imagesData[0]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col xs={12}>
                <Img fluid={imagesData[1]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="justify-content-center">
              <CompanyLogo name="vortex" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{ name: "Santorini Heights", link: "/work/santorini" }}
            prevProject={{
              name: "Gio’s Masterclass",
              link: "/work/giomasterclass",
            }}
            selectedLanguage="pt"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Vortex;

export const VortexQuery = graphql`
  query VortexPhotosPt {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/vortex/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
